import { SortBy } from '@enview/interface/types/BillSearch';
import { Bill } from '@enview/interface/types/bills/Bill';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BillAPI, TagAPI } from '../../../api';
import { getOrgIdForTeamMode, getTeamModeTeam } from '../../../dux';
import { sortTags } from '../../../helpers/TagHelper';
import { SortOrder } from '../../../models/SortOrder';
import TextButton from '../Buttons/TextButton';
import { useUrlCreator } from '../../../helpers/UrlHelper';
import ValueBadge from '../Badges/ValueBadge';

type BillTagContentProps = {
  billData: Bill | Bill[];
};

const BillTagContent = (props: BillTagContentProps): ReactElement => {
  const { billData } = props;
  const [moreTags, setMoreTags] = useState(false);
  const viewMoreTags = moreTags
    ? 'Hide tags from other workspaces'
    : 'View tags from other workspaces';

  const team = useSelector(getTeamModeTeam);
  const orgId = useSelector(getOrgIdForTeamMode);

  // all tags in all organizations
  const { data: allTags } = TagAPI.endpoints.getTags.useQuery(undefined);
  const [untagBill] = BillAPI.endpoints.untagBill.useMutation();
  const [tagBill] = BillAPI.endpoints.tagBill.useMutation();

  // Bulk actions
  const [bulkUntagBills] = BillAPI.endpoints.bulkUntagBills.useMutation();
  const [bulkTagBills] = BillAPI.endpoints.bulkTagBills.useMutation();

  // all tags in the workspace
  const tags = useMemo(
    () => allTags?.filter((tag) => tag.teamId === team?.id),
    [team, allTags],
  );

  // all tags in this organization excluding current workspace
  const orgTags = useMemo(
    () =>
      allTags?.filter(
        (tag) => tag.team?.organizationId === orgId && !tags?.includes(tag),
      ),
    [team, allTags],
  );

  const TAG_SORT_ORDER = new SortOrder(SortBy.ALPHABETICAL, false);
  const sortedTags = tags ? sortTags(tags, TAG_SORT_ORDER) : tags;

  const topOrgTags = useRef<null | HTMLDivElement>(null);

  const tagScroll = (): void => {
    if (topOrgTags && topOrgTags.current && moreTags) {
      topOrgTags.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(tagScroll, [moreTags]);
  const urlCreator = useUrlCreator();

  const openManageTags = (): void => {
    const tagsUrl = urlCreator(`/account/tags`);
    window.open(tagsUrl, '_blank');
  };

  const moreTagsButton = (
    <TextButton
      className="link-style ml-auto"
      label={viewMoreTags}
      onClick={() => {
        setMoreTags(!moreTags);
      }}
      style={{ display: 'block', margin: 'auto' }}
    />
  );

  const openManageTagsButton = (
    <div className="d-flex flex-row">
      <TextButton
        className="link-style ml-auto"
        label="Manage Tags"
        onClick={() => openManageTags()}
        style={{ paddingRight: '8px' }}
      />
      <div>
        <FontAwesomeIcon
          color="#979797"
          icon={faExternalLinkAlt}
          onClick={() => openManageTags()}
          size="sm"
        />
      </div>
    </div>
  );

  // Tag/Untag single bill
  if (!Array.isArray(billData)) {
    const bill = { ...billData };
    return (
      <div>
        <div className="tag-selector">
          {tags &&
            sortedTags &&
            sortedTags.map((tag) => {
              const checked = !!bill.tags.find((x) => x.id === tag.id);
              return (
                <div key={tag.id}>
                  <label className="d-flex align-items-center" htmlFor={`${tag.id}`}>
                    <input
                      checked={checked}
                      id={`${tag.id}`}
                      name={`${tag.id}`}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        e.target.checked
                          ? tagBill({ bill, tag })
                          : untagBill({ bill, tag })
                      }
                      type="checkbox"
                    />{' '}
                    <span className="label-text">{tag.name}</span>
                    {tag.isPublic && <ValueBadge size="xs" value="Public" />}
                  </label>
                </div>
              );
            })}
          <div ref={topOrgTags} />
          {moreTags ? <hr /> : null}
          {moreTags &&
            orgTags &&
            orgTags.map((tag) => {
              const checked = !!bill.tags.find((x) => x.id === tag.id);
              return (
                <div key={tag.id}>
                  <label htmlFor={`${tag.id}`}>
                    <input
                      checked={checked}
                      id={`${tag.id}`}
                      name={`${tag.id}`}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        e.target.checked
                          ? tagBill({ bill, tag })
                          : untagBill({ bill, tag })
                      }
                      type="checkbox"
                    />{' '}
                    <span className="label-text">
                      {tag.name}{' '}
                      <span className="label-workspace">({tag.team?.name})</span>
                    </span>
                  </label>
                </div>
              );
            })}
        </div>
        {moreTagsButton}
        {openManageTagsButton}
      </div>
    );
  } else {
    // Bulk Tag/Untag
    const bills = [...billData];
    return (
      <div>
        <div className="tag-selector">
          {tags &&
            sortedTags &&
            sortedTags.map((tag) => {
              const checked =
                bills?.length > 0 &&
                bills?.every(
                  (bill) =>
                    bill?.tags?.length > 0 && bill?.tags?.some((x) => x.id === tag.id),
                );
              const striked =
                !checked &&
                bills?.length > 0 &&
                bills?.some(
                  (bill) =>
                    bill?.tags?.length > 0 && bill?.tags?.some((x) => x.id === tag.id),
                );
              return (
                <div key={tag.id}>
                  <label htmlFor={`${tag.id}`}>
                    <input
                      checked={checked}
                      id={`${tag.id}`}
                      name={`${tag.id}`}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        e.target.checked
                          ? bulkTagBills({ bills, tag })
                          : bulkUntagBills({ bills, tag })
                      }
                      ref={(elem) => elem && (elem.indeterminate = striked)}
                      type="checkbox"
                    />
                    <span className="label-text">{tag.name}</span>
                  </label>
                </div>
              );
            })}
          <div ref={topOrgTags} />
          {moreTags ? <hr /> : null}
          {moreTags &&
            orgTags &&
            orgTags.map((tag) => {
              const checked =
                bills?.length > 0 &&
                bills?.every(
                  (bill) =>
                    bill?.tags?.length > 0 && bill?.tags?.every((x) => x.id === tag.id),
                );
              const striked =
                !checked &&
                bills?.length > 0 &&
                bills?.some(
                  (bill) =>
                    bill?.tags?.length > 0 && bill?.tags?.some((x) => x.id === tag.id),
                );
              return (
                <div key={tag.id}>
                  <label htmlFor={`${tag.id}`}>
                    <input
                      checked={checked}
                      id={`${tag.id}`}
                      name={`${tag.id}`}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        e.target.checked
                          ? bulkTagBills({ bills, tag })
                          : bulkUntagBills({ bills, tag })
                      }
                      ref={(input) => {
                        if (input && striked) {
                          input.indeterminate = true;
                        }
                      }}
                      type="checkbox"
                    />{' '}
                    <span className="label-text">
                      {tag.name}{' '}
                      <span className="label-workspace">({tag.team?.name})</span>
                    </span>
                  </label>
                </div>
              );
            })}
        </div>
        {moreTagsButton}
        {openManageTagsButton}
      </div>
    );
  }
};

export default BillTagContent;
