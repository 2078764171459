import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import fflip from 'fflip';

export enum FrontendFeature {
  BillTextDebug = 'bill-text-debug',
  BillVersionSourceLink = 'bill-version-source-link',
  BillSimilarBillsRelevanceSwitch = 'bill-similar-bills-relevance-switch',
  SavedSearchAlerts = 'saved-search-alerts',
  IdenticalBills = 'identical-bills',
  CollapsibleSideMenu = 'collapsible-sidemenu',
  BillTableView = 'bill-table-view',
  FilteredBillTableView = 'filtered-bill-table-view',
  VoteData = 'vote-data',
  ServerSideBillTableSorting = 'server-side-bill-table-sorting',
  RegulationsSearch = 'regulations-search',
  CodifyBillRelations = 'codify-bill-relations',
  JurisdictionOverviewRedesign = 'jurisdiction-overview-redesign',
  NewJurisdictionSelectorForRegs = 'new-jurisdiction-selector-for-regs',
  LegislatorStaffDirectoryTab = 'legislator-staff-directory-tab',
  CommitteeStaffDirectoryTab = 'committee-staff-directory-tab',
  WorkspaceNotificationSettings = 'workspace-notification-settings',
  JurisdictionHearings = 'jurisdiction-hearings',
  EndOfSessionReport = 'end-of-session-report',
  isCanViewCemlInMissingJurisdictions = 'can-view-ceml-in-missing-jurisdictions',
  BillPositionStatement = 'bill-position-statement',
  WorkspaceManagementView = 'workspace-management-view',
}

const mplOrgsIDs = [3, 4, 5, 6, 7, 11, 12, 13, 14, 17, 18];
const testOrgIDs = [165];

fflip.config({
  criteria: [
    {
      id: 'isCivicEagleStaff',
      check: (user: OrganizationUser | undefined) =>
        user && user.organizations.map((o) => o.id).includes(1),
    },
    {
      id: 'isMPLOrgsUser',
      check: (user: OrganizationUser | undefined) =>
        user && !!user.organizations.find((org) => mplOrgsIDs.includes(org.id)),
    },
    {
      id: 'isAutomatedTestingUser',
      check: (user: OrganizationUser | undefined) =>
        user &&
        !!user.organizations.find((org) => testOrgIDs.includes(org.id)) &&
        process.env.REACT_APP_ENV?.toLowerCase() === 'dev',
    },
    {
      id: 'isDevEnvironment',
      check: () =>
        process.env.REACT_APP_ENV?.toLowerCase() === 'dev' ||
        process.env.REACT_APP_ENV?.toLowerCase() === undefined,
    },
    {
      id: 'isCanViewCemlInMissingJurisdictions',
      check: (user: OrganizationUser | undefined) =>
        user &&
        user.teamMemberships.find(
          (t) => t.team.name === FrontendFeature.isCanViewCemlInMissingJurisdictions,
        ),
    },
    {
      id: 'anyone',
      check: (anyone: any) => anyone,
    },
    {
      id: 'nobody',
      check: () => false,
    },
  ],
  // If criteria is an object, ALL criteria must evaluate to true to enable feature flag
  // If criteria is an array, ANY ONE set of criteria must evaluate to true to enable feature flag
  features: [
    {
      id: FrontendFeature.BillTextDebug,
      criteria: { isCivicEagleStaff: true },
    },
    {
      id: FrontendFeature.BillVersionSourceLink,
      criteria: { isCivicEagleStaff: true },
    },
    {
      id: FrontendFeature.BillSimilarBillsRelevanceSwitch,
      criteria: { isCivicEagleStaff: true },
    },
    {
      id: FrontendFeature.SavedSearchAlerts,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.IdenticalBills,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.CollapsibleSideMenu,
      criteria: [{ isCivicEagleStaff: true }, { isAutomatedTestingUser: true }],
    },
    {
      id: FrontendFeature.FilteredBillTableView,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.VoteData,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.CodifyBillRelations,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.RegulationsSearch,
      criteria: [{ isCivicEagleStaff: true }, { isAutomatedTestingUser: true }],
    },
    {
      id: FrontendFeature.ServerSideBillTableSorting,
      criteria: [{ isCivicEagleStaff: true }, { isAutomatedTestingUser: true }],
    },
    {
      // TODO: Get this rolled out for internal users when regs testing resumes
      id: FrontendFeature.NewJurisdictionSelectorForRegs,
      criteria: [{ isAutomatedTestingUser: true }],
    },
    {
      id: FrontendFeature.JurisdictionOverviewRedesign,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.LegislatorStaffDirectoryTab,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.WorkspaceNotificationSettings,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.CommitteeStaffDirectoryTab,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.JurisdictionHearings,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.EndOfSessionReport,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.isCanViewCemlInMissingJurisdictions,
      criteria: [{ isCanViewCemlInMissingJurisdictions: true }],
    },
    {
      id: FrontendFeature.BillPositionStatement,
      criteria: { anyone: true },
    },
    {
      id: FrontendFeature.WorkspaceManagementView,
      criteria: [{ isCivicEagleStaff: true }, { isAutomatedTestingUser: true }],
    },
  ],
});

export default {
  getFeaturesForUser: (user: any) => fflip.getFeaturesForUser(user),
  isFeatureEnabledForUser: (featureName: FrontendFeature, user: any) => {
    const isEnabled = fflip.isFeatureEnabledForUser(featureName, user);
    if (isEnabled === undefined || isEnabled === null) {
      throw new Error(`Feature flag ${featureName} configuration not found.`);
    }

    return isEnabled;
  },
};
