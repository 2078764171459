import { ReactElement } from 'react';
import './List.scss';

import { BillSummary } from '@enview/interface/types/bills/BillSummary';
import NoBillResults from '../../LegislativeTracking/NoBillResults';
import BillListItem from './BillListItem';

type BillListProps = {
  bills: BillSummary[];
  noBillsMessage?: string;
};

const BillList = ({ bills, noBillsMessage }: BillListProps): ReactElement => {
  if (bills.length === 0) {
    return <NoBillResults noBillsMessage={noBillsMessage} />;
  }
  return (
    <div className="row">
      <div className="col-12" id="bill-list">
        {bills.map((bill) => {
          return bill ? <BillListItem bill={bill} key={bill.id} /> : <></>;
        })}
      </div>
    </div>
  );
};

export default BillList;
