/* eslint-disable react/require-default-props */
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import Loader from 'react-loader';
import { useSelector } from 'react-redux';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { Bill } from '@enview/interface/types/bills/Bill';
import BillList from '../Shared/Lists/BillList';
import Paginator from '../Shared/PageElements/Paginator';

import { SortBy, SortOrder } from '../../models/SortOrder';
import { OptionType } from '../Shared/DropDowns/MultipleSelectDropDown';

import { BillSearchView } from '@enview/interface/types/BillSearch';
import * as Analytics from '../../analytics/TeamAnalytics';
import { BillAPI, TagAPI } from '../../api';
import { getJurisdictionsForTeamMode, getTeamMode } from '../../dux/TeamModeDux';
import featureFlags, { FrontendFeature } from '../../featureFlags';
import { sortBillsBySortLabel } from '../../utils';
import SortAndFilterHeader from '../Shared/Lists/SortAndFilterHeader';
import BillTable from '../Shared/Tables/BillTable';

const NO_BILL_MESSAGE = "You haven't tracked any bills yet.";
const TEAM_NO_BILL_MESSAGE = 'Your team has not tracked any bills yet.';
const TEAM_SORT_BILL_OPTIONS = [
  new SortOrder(SortBy.RECENT, false),
  new SortOrder(SortBy.RECENT, true),
  new SortOrder(SortBy.ALPHANUMERICAL, false),
  new SortOrder(SortBy.ALPHANUMERICAL, true),
];

type TeamBillsProps = {
  orgUser: OrganizationUser;
  showJurisdictionFilter: boolean;
  filterToJurisdictions?: string[];
  noBillsMessage?: string;
  view?: BillSearchView;
};

const TeamBills = (props: TeamBillsProps): ReactElement => {
  const {
    orgUser,
    showJurisdictionFilter,
    filterToJurisdictions,
    noBillsMessage: noBillsMsgFromProps,
    view = BillSearchView.Card,
  } = props;
  const teamModeId = useSelector(getTeamMode);
  const orgJurisdictions = useSelector(getJurisdictionsForTeamMode);
  const filteredBillTableViewAvailable = featureFlags.isFeatureEnabledForUser(
    FrontendFeature.FilteredBillTableView,
    orgUser,
  );
  const [sortOrder, setSortOrder] = useState(new SortOrder(SortBy.RECENT, false));
  const { trackedBills } = BillAPI.endpoints.getTrackedBills.useQuery(undefined, {
    selectFromResult: ({ data: bills }): { trackedBills: Bill[] } => {
      if (!bills) return { trackedBills: [] };
      return {
        trackedBills: sortBillsBySortLabel(
          bills.filter((b) => !!b.teams?.find((t) => t.id === teamModeId)),
          sortOrder,
        ),
      };
    },
  });
  const { data: filteredTags } = TagAPI.endpoints.getTags.useQuery(undefined);

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
  });

  let noBillsMessage = noBillsMsgFromProps;
  if (!noBillsMessage) {
    noBillsMessage =
      teamModeId === orgUser.teamId ? NO_BILL_MESSAGE : TEAM_NO_BILL_MESSAGE;
  }
  const initialJurisdictions =
    filterToJurisdictions && filterToJurisdictions.length > 0
      ? filterToJurisdictions.map((jurisAbbr) => jurisAbbr.toLowerCase())
      : [];
  const [selectedJurisdictions, setSelectedJurisdictions] =
    useState<string[]>(initialJurisdictions);
  const [searching, setSearching] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const trackFilter = useCallback(() => {
    Analytics.trackFilterWorkspaceBills(selectedJurisdictions, teamModeId, orgUser);
  }, [selectedJurisdictions, teamModeId, orgUser]);

  useEffect(() => {
    trackFilter();
  }, [trackFilter, selectedJurisdictions]);

  useEffect(() => {
    setQuery({ page: query.page });
    window.scrollTo(0, 0);
  }, [query]);

  const filterOptions = useMemo((): OptionType<string>[] => {
    const jurisdictionsTracked = trackedBills
      .map((bill) => bill.session.state.abbreviation)
      .filter((item, i, ar) => ar.indexOf(item) === i);
    return orgJurisdictions
      .filter((jur) => jurisdictionsTracked.includes(jur.abbreviation))
      .map((jur) => {
        return {
          label: jur.name,
          value: jur.abbreviation,
        };
      });
  }, [trackedBills]);

  const tagOptions = useMemo((): OptionType<string>[] | undefined => {
    return filteredTags?.map((tag) => {
      return {
        label: tag.name,
        value: tag.id.toString(),
      };
    });
  }, [filteredTags]);

  const filteredTrackedBills = useMemo(() => {
    let filteredBills = [];

    filteredBills =
      selectedJurisdictions.length === 0
        ? trackedBills
        : trackedBills.filter((bill) => {
            const jur = bill.session.state.abbreviation;
            return selectedJurisdictions.includes(jur);
          });

    if (
      selectedTags.length &&
      filteredTags &&
      selectedTags.length !== tagOptions?.length
    ) {
      // if all tags selected, no need for filter
      filteredBills = filteredBills.filter((bill) =>
        bill.tags.some((tag) => selectedTags.includes(tag.id.toString())),
      );
    }

    return filteredBills;
  }, [selectedJurisdictions, selectedTags, trackedBills]);

  // Pagination
  const pageSize = 25;
  const pages = {
    page: query.page,
    total: filteredTrackedBills.length,
    pageSize,
  };
  const offset = (query.page - 1) * pageSize;
  const currentPageOfBills = filteredTrackedBills.slice(offset, offset + pageSize);
  if (!filteredTrackedBills) {
    return <Loader loaded={filteredTrackedBills} />;
  }

  const renderBillList: () => ReactElement = () => {
    return (
      <>
        <SortAndFilterHeader
          filterLabel={'Jurisdiction'}
          filterOptions={showJurisdictionFilter ? filterOptions : undefined}
          selectedFilters={selectedJurisdictions}
          selectedTags={selectedTags}
          setSelectedFilters={setSelectedJurisdictions}
          setSelectedTags={setSelectedTags}
          setSortOrder={setSortOrder}
          showViewDropdown={filteredBillTableViewAvailable}
          sortOptions={TEAM_SORT_BILL_OPTIONS}
          sortOrder={sortOrder}
          tagFilterOptions={tagOptions}
          totalBills={pages.total}
        />
        <BillList bills={currentPageOfBills} noBillsMessage={noBillsMessage} />
        <Paginator onChange={(page) => setQuery({ page })} pages={pages} />
      </>
    );
  };

  const renderBillTable: () => ReactElement = () => {
    const results = {
      data: filteredTrackedBills,
      count: filteredTrackedBills?.length,
    };
    const jurisdictionFilterProperties = {
      show: showJurisdictionFilter,
      filterOptions: filterOptions,
      selectedFilters: selectedJurisdictions,
      setSelectedFilters: setSelectedJurisdictions,
      tagFilterOptions: tagOptions,
      setSelectedTags,
      selectedTags,
    };
    return (
      <BillTable
        filterProperties={showJurisdictionFilter ? jurisdictionFilterProperties : {}}
        query={query}
        results={results}
        searching={searching}
        setQuery={setQuery}
        setSearching={setSearching}
      />
    );
  };

  return (
    <div className="bill-search-container">
      <div className="results-pane list">
        {view === BillSearchView.Card || !filteredBillTableViewAvailable
          ? renderBillList()
          : renderBillTable()}
      </div>
    </div>
  );
};

export default TeamBills;
