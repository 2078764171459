import { BillStatus } from '@enview/interface/types/Bill';
import { Bill } from '@enview/interface/types/bills/Bill';
import { ReactElement } from 'react';

type BillStatusBadgeProps = {
  bill: Bill;
};

const BillStatusBadge = (props: BillStatusBadgeProps): ReactElement => {
  const { bill } = props;
  const status = (bill.status as BillStatus) ?? BillStatus.INTRODUCED;

  const translateBillStatusForBadge = (billStatus: BillStatus): string => {
    const map = {
      [BillStatus.LAW]: 'Law',
      [BillStatus.GOVERNOR]: 'Signed',
      [BillStatus.INTRODUCED]: 'Introduced',
      [BillStatus.PASSED]: 'Passed',
      [BillStatus.PASSED_LOWER]: 'Passed Lower',
      [BillStatus.PASSED_UPPER]: 'Passed Upper',
      [BillStatus.FIRST_READING]: 'First Reading',
      [BillStatus.SECOND_READING]: 'Second Reading',
      [BillStatus.THIRD_READING]: 'Third Reading',
      [BillStatus.REFERRAL_COMMITTEE]: 'Referred to Committee',
      [BillStatus.REPORTED_OUT_OF_COMMITTEE]: 'Reported out of Committee',
      [BillStatus.CONCURRENCE]: 'Sent for Concurrence',
      [BillStatus.WITHDRAWAL]: 'Withdrawn',
      [BillStatus.VETOED]: 'Vetoed',
    };

    if (map[billStatus]) {
      return map[billStatus];
    } else throw new Error(`Bill status ${status} not found in map`);
  };

  return (
    <button
      className={`btn btn-sm btn-secondary toggle bill-status-toggle ${status?.toLowerCase()}`}
      disabled
      type="button"
    >
      {translateBillStatusForBadge(status)}
    </button>
  );
};

export default BillStatusBadge;
