/* eslint-disable @typescript-eslint/unbound-method */
import React, {
  ChangeEventHandler,
  LegacyRef,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ConfirmActionPopover, {
  PopoverConfirmProps,
} from '../Popovers/ConfirmActionPopover';
import CommentCheckIcon from './CommentCheckIcon';
import { useTranslation } from 'react-i18next';
import Can from '../../../components/Can';
import { g as abilityGlossary } from '../../../config/ability';
import RichTextEditor from '../ActivityFeed/CommentForm/RichTextEditor';
import { Descendant } from 'slate';
import './BillPosition.scss';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BillAPI } from '../../../api';
import { Bill } from '@enview/interface/types/bills/Bill';
import { PositionType } from '@enview/interface/types/bills/PositionStatement';
import {
  isElementEmpty,
  serializeToPlainText,
  serializeToReactElement,
} from '@enview/interface/utils/RichTextAnnotation';
import sanitizeHtml from 'sanitize-html';
import { UserAction } from '@enview/interface/types/actions/UserAction';
import { useSelector } from 'react-redux';
import { State } from '../../../dux/@types';
import { CreatePositionDto } from '../../../api/bill';
import { TFunction } from 'i18next';
import {
  trackBillPositionAdded,
  trackBillPositionDeleted,
  trackBillPositionUpdated,
} from '../../../analytics/BillAnalytics';
import SwitchIcon from '../Icons/SwitchIcon';

interface PositionStatementPopoverProps extends Partial<PopoverConfirmProps> {
  bill: Bill;
  target: ReactElement;
  show: boolean;
  toggleCallback: (evt: React.SyntheticEvent) => void;
  closePopup: () => void;
}
interface RenderCheckboxProps {
  value: 'support' | 'oppose';
  checkboxValue: string[];
  handleCheckboxChange: ChangeEventHandler<HTMLInputElement>;
  register: LegacyRef<HTMLInputElement> | undefined;
  t: TFunction<'common', undefined>;
  disabled?: boolean;
}
type PositionFormInput = { checkbox: string[]; isPublic: boolean };

const PLACEHOLDER_TEXT = 'Add a position statement to this bill (optional)';

const renderCheckbox = ({
  value,
  checkboxValue,
  handleCheckboxChange,
  register,
  t,
  disabled = false,
}: RenderCheckboxProps) => (
  <div className="form-check input-group">
    <input
      checked={checkboxValue.includes(value)}
      className="form-check-input"
      disabled={disabled}
      id={`${value}Checkbox`}
      name="checkbox"
      onChange={handleCheckboxChange}
      ref={register}
      type="checkbox"
      value={value}
    />
    <label className="form-check-label" htmlFor={`${value}Checkbox`}>
      {t(`bill.sidebar.positionStatement.${value}`)}
    </label>
  </div>
);

export const PositionStatementPopover: React.FC<PositionStatementPopoverProps> = ({
  bill,
  target,
  show,
  toggleCallback,
  closePopup,
  ...props
}) => {
  const [editMode, setEditMode] = useState(false);
  const [comment, setComment] = useState(PLACEHOLDER_TEXT as any);
  const [hasStatement, setHasStatement] = useState(true);
  const { t } = useTranslation();
  const nodeRef = useRef<any>();

  const [addPosition, { isLoading }] =
    BillAPI.endpoints.createPositionStatement.useMutation();
  const [updatePosition] = BillAPI.endpoints.updatePositionStatement.useMutation();
  const [deletePosition] = BillAPI.endpoints.deletePositionStatement.useMutation();

  const orgId = useSelector((state: State) => state.teamMode.orgId);
  const orgUser = useSelector((state: State) => state.account.organizationUser);

  const positionStatement = bill.positionStatements && bill.positionStatements[0];

  const supportsBill = positionStatement?.positionType === PositionType.SUPPORT;

  useEffect(() => {
    if (!positionStatement) {
      setHasStatement(false);
      setEditMode(true);
    } else {
      setHasStatement(true);
    }
  }, [positionStatement]);

  const getComments = useMemo(() => {
    const statement: Partial<UserAction> = { comment: '' };
    const isEmptyText =
      typeof comment !== 'string' && serializeToPlainText({ children: comment }) === '';

    if (!isEmptyText && comment !== PLACEHOLDER_TEXT) {
      if (typeof comment === 'object') {
        statement.commentJson = { children: comment };
      } else {
        statement.comment = sanitizeHtml(comment.replace(/<div>/g, ' '), {
          allowedTags: [],
        });
      }
    }

    return statement;
  }, [comment]);

  const getUserAction = useMemo(() => {
    if (!positionStatement) return;
    const { statementJson, positionStatement: statementComment } = positionStatement;

    if (statementComment || statementJson) {
      return {
        comment: statementComment,
        commentJson: statementJson,
      } as Partial<UserAction>;
    }
  }, [positionStatement]);

  const getPositionType = (checked: string[]) => {
    if (checked.includes('oppose')) return PositionType.OPPOSE;
    if (checked.includes('support')) return PositionType.SUPPORT;
    return PositionType.NO_POSITION;
  };

  let positionComment = <></>;
  if (!!positionStatement && Object.keys(positionStatement).length > 0) {
    positionComment = positionStatement.statementJson ? (
      <span>
        {serializeToReactElement(positionStatement.statementJson, orgUser?.id)}
      </span>
    ) : (
      <span>{positionStatement.positionStatement}</span>
    );
  }

  const getDefaultPositionType = () => {
    const values: string[] = [];
    if (!positionStatement) {
      return [];
    }

    if (positionStatement.positionType) {
      values.push(positionStatement.positionType.toLowerCase());
    }
    return values;
  };

  const getDefaultVisibility = () => {
    if (positionStatement && positionStatement.isPublic) return true;
    return false;
  };

  const { register, handleSubmit, setValue, watch } = useForm<PositionFormInput>({
    defaultValues: {
      checkbox: getDefaultPositionType(),
      isPublic: getDefaultVisibility(),
    },
  });
  useEffect(() => register('isPublic'), []);

  const checkboxValue = watch('checkbox');
  const publishStatus = watch('isPublic');

  const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setValue('checkbox', [value]);
    } else {
      setValue('checkbox', []);
    }
  };

  const onClosePopup = () => {
    setEditMode(!hasStatement);
    closePopup();
  };

  const onSubmit: SubmitHandler<PositionFormInput> = async (data) => {
    if (!orgId) {
      return;
    }

    const formData: CreatePositionDto = {
      positionType: getPositionType(data.checkbox),
      isPublic: data.isPublic,
      positionStatement: getComments.comment || '',
      orgId,
      billId: bill.id,
    };

    if (getComments.commentJson) {
      formData.statementJson = getComments.commentJson;
    }

    // Do nothing if neither position stance nor comment is set
    if (
      !formData.positionStatement &&
      formData.positionType === PositionType.NO_POSITION &&
      !formData.statementJson
    ) {
      return;
    }

    const hasStance = formData.positionType !== PositionType.NO_POSITION;
    const hasComment = !!(formData.positionStatement || formData.statementJson);

    if (positionStatement && hasStatement) {
      await updatePosition({
        id: positionStatement.id,
        updateData: formData,
      });
      trackBillPositionUpdated(
        bill.id,
        hasStance,
        hasComment,
        formData.isPublic || false,
      );
    } else {
      await addPosition(formData);
      trackBillPositionAdded(
        bill.id,
        hasStance,
        hasComment,
        formData.isPublic || false,
      );
    }

    onClosePopup();
    setEditMode(false);
  };

  const onDeletePosition = async () => {
    if (!positionStatement) {
      return;
    }
    await deletePosition({
      id: positionStatement.id,
    });
    trackBillPositionDeleted(bill.id);

    onClosePopup();
  };

  const onCancel = () => {
    setEditMode(false);
    onClosePopup();
  };

  const onChangeToEditor = (event: Descendant[]) => {
    setComment(event);
  };

  return (
    <ConfirmActionPopover
      {...props}
      closePopup={onClosePopup}
      content={
        editMode ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className="popover-header">
              <CommentCheckIcon className="mr-2" color={'#212529'} />
              {t('bill.sidebar.positionStatement.modalheader')}
            </h4>
            {renderCheckbox({
              value: 'support',
              checkboxValue,
              handleCheckboxChange,
              register,
              t,
            })}
            {renderCheckbox({
              value: 'oppose',
              checkboxValue,
              handleCheckboxChange,
              register,
              t,
            })}
            <div className="rich-text-editor my-4" id="text-editor" ref={nodeRef}>
              <RichTextEditor
                onChangeToEditor={onChangeToEditor}
                placeholder={PLACEHOLDER_TEXT}
                userAction={getUserAction}
              />
            </div>
            <Can
              I={abilityGlossary.PUBLISH}
              a={abilityGlossary.BILL_POSITION}
              passThrough
            >
              {(can: boolean) =>
                can && (
                  <SwitchIcon
                    additionalExplanation={t(
                      `bill.sidebar.positionStatement.additionalExplanation`,
                    )}
                    disabled={!can}
                    id="isPublicCheckbox"
                    labelClassName="font-14"
                    offDescription={t(`bill.sidebar.positionStatement.publishOff`)}
                    onChange={(value) => {
                      setValue('isPublic', value);
                    }}
                    onDescription={t(`bill.sidebar.positionStatement.publishOn`)}
                    size="lg"
                    value={publishStatus}
                  />
                )
              }
            </Can>
            <div className="d-flex justify-content-start mt-3">
              <button className="btn primary-btn" disabled={isLoading} type="submit">
                Save
              </button>
              <button className="btn ml-3" onClick={() => onCancel()}>
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <div>
            <h4 className="popover-header">
              <CommentCheckIcon className="mr-2" color={'#212529'} />
              {t('bill.sidebar.positionStatement.modalheader')}
            </h4>
            <div className="content-body my-3">
              {positionStatement &&
                positionStatement.positionType !== PositionType.NO_POSITION && (
                  <div
                    className="position-button mb-2"
                    style={{ backgroundColor: supportsBill ? '#00cbb1' : '#f40404E8' }}
                  >
                    {positionStatement?.positionType}
                  </div>
                )}
              {!isElementEmpty(positionComment) && (
                <div className="statement-box mt-4">{positionComment}</div>
              )}
            </div>
            <div className="d-flex justify-content-start footer">
              <button className="btn primary-btn" onClick={() => setEditMode(true)}>
                Edit
              </button>
              <button className="btn delete-btn ml-3" onClick={onDeletePosition}>
                Delete
              </button>
            </div>
          </div>
        )
      }
      show={show}
      target={target}
      toggleCallback={toggleCallback}
    />
  );
};
