import { Tag } from '@enview/interface/types/tags/Tag';
import { SortOrder, SortBy } from '../models/SortOrder';

export const sortTagsByAlpha = (tags: Tag[], isReversed: boolean) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  const newTags = tags;
  return [...newTags].sort((a: Tag, b: Tag) => {
    if (isReversed) {
      return collator.compare(`${b.name}`, `${a.name}`);
    }
    return collator.compare(`${a.name}`, `${b.name}`);
  });
};

export const sortTagsByDateCreated = (tags: Tag[], isReversed: boolean) => {
  const newTags = tags;
  if (isReversed) {
    return [...newTags].sort((a: Tag, b: Tag) => {
      if (a.createdAt < b.createdAt) return -1;
      if (a.createdAt > b.createdAt) return 1;
      return 0;
    });
  }

  return [...newTags].sort((a: Tag, b: Tag) => {
    if (a.createdAt < b.createdAt) return 1;
    if (a.createdAt > b.createdAt) return -1;
    return 0;
  });
};

export const sortTags = (tags: Tag[], sortOrder: SortOrder): Tag[] => {
  if (!tags.length) {
    return [];
  }
  if (!sortOrder) {
    return sortTagsByAlpha(tags, false);
  }
  const { sortBy, isReversed } = sortOrder;
  switch (sortBy) {
    case SortBy.CREATED:
      return sortTagsByDateCreated(tags, isReversed);
    case SortBy.ALPHANUMERICAL:
    default:
      return sortTagsByAlpha(tags, isReversed);
  }
};
