/* eslint-disable react/require-default-props */
import { Bill } from '@enview/interface/types/bills/Bill';
import { ReactElement } from 'react';
import BillBulkActionsDropdown from '../DropDowns/BillBulkActionsDropdown';
import BillViewDropdown from '../DropDowns/BillViewDropdown';
import MultipleSelectDropDown, {
  OptionType,
} from '../DropDowns/MultipleSelectDropDown';

type BillTableHeaderProps = {
  results: { data: Bill[]; count: number };
  selectedRows: Bill[];
  filterProperties?: {
    show?: boolean;
    filterLabel?: string;
    selectedFilters?: string[];
    filterOptions?: OptionType<string>[];
    setSelectedFilters?: (selection: string[]) => void;
    tagFilterOptions?: OptionType<string>[];
    selectedTags?: string[];
    setSelectedTags?: (tags: string[]) => void;
  };
};
const BillTableHeader = (props: BillTableHeaderProps): ReactElement => {
  const { filterProperties, results, selectedRows } = props;
  const {
    filterLabel = 'Jurisdiction',
    selectedFilters = [],
    filterOptions = [],
    setSelectedFilters = () => {},
    tagFilterOptions,
    setSelectedTags,
    selectedTags,
  } = filterProperties || {};

  return (
    <div className="row bill-table-header">
      {filterProperties?.show && (
        <div className="jurisdiction-filter result-total">
          <div className="filter-group">
            <div className="total-count">
              <span>
                {results?.count ?? 0} bill{results && results?.count == 1 ? '' : 's'}
              </span>
            </div>
            <div className="filter jurisdictions">
              <MultipleSelectDropDown
                currentSelectedValues={selectedFilters}
                onChange={(selectedFilterValues) => {
                  setSelectedFilters(selectedFilterValues);
                }}
                options={filterOptions}
                title={`Filter by ${filterLabel}`}
              />
            </div>
            {selectedTags && tagFilterOptions && setSelectedTags && (
              <div className="filter tagnames">
                <MultipleSelectDropDown
                  currentSelectedValues={selectedTags}
                  onChange={(selectedTagValues) => {
                    setSelectedTags(selectedTagValues);
                  }}
                  options={tagFilterOptions}
                  title={`Filter by Tag Names`}
                />
              </div>
            )}
          </div>
          <div className="actions-view-group">
            <BillBulkActionsDropdown bills={selectedRows} />
            <div className="view-dropdown dropdown-container">
              <BillViewDropdown />
            </div>
          </div>
        </div>
      )}

      {!filterProperties?.show && (
        <>
          <div className="filtered-group col-lg-10 col-xs-12 result-total">
            <div className="result-dropdown-container">
              <div className="total-count">
                <span>
                  {results?.count ?? 0} bill{results && results?.count == 1 ? '' : 's'}
                </span>
              </div>
              <BillBulkActionsDropdown bills={selectedRows} />
            </div>
          </div>
          <div
            className="view-dropdown col-lg-1 col-xs-12 dropdown-container"
            style={{ paddingLeft: '74px' }}
          >
            <BillViewDropdown />
          </div>
        </>
      )}
    </div>
  );
};

export default BillTableHeader;
